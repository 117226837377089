import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import Jumbotron from '../../components/Jumbotron';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Jumbotron
          jumbotron={{
            image: '/img/martin-adams-a_pdpupunz8-unsplash.jpg',
            heading: 'Latest Stories',
          }}
        />
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
